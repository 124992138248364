import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import Link from "next/link";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "@shared/lib/components/common/atom/button";
import { CheckBox } from "@shared/lib/components/common/atom/check-box";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { TimerSpan } from "@shared/lib/components/common/atom/span/timer-span";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { MENU } from "@/constants/menu";
import { MAX_WIDTH } from "@/constants/style/layout";
import { getFormattedPhoneNumber, phoneRegex } from "@/utils/string";

import {
  useAuthControllerSendVerification,
  useAuthControllerSignUp,
  useAuthControllerVerifyCode,
} from "@shared/generated/api/fn/kac/auth/auth";
import {
  SendVerificationRequestDtoType,
  SignUpRequestDtoSocialType,
} from "@shared/generated/api/model";
import { useTranslation } from "react-i18next";

interface SignUpForm {
  name: string;
  password?: string;
  email: string;
  matchNewPassword?: string;
  phoneNumber: string;
  verificationCode: string;
  isAgeAgree: boolean;
  isServiceAgree: boolean;
  isPrivateAgree: boolean;
  marketingOptIn?: boolean;
  isProvider?: boolean;
}
interface QueryParams {
  socialType?: string;
  socialId?: string;
  email?: string;
  name?: string;
}

export const SignUpTemplate = () => {
  const { t } = useTranslation();
  const [initialEmail, setIsInitialEmail] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowMatchPassword, setIsShowMatchPassword] = useState(false);
  const [verifyActionCount, setVerifyActionCount] = useState(0);
  const [isVerifyNumberDisabled, setIsVerifyNumberDisabled] =
    useState<boolean>(true);
  const [isSuccessVerify, setIsSuccessVerify] = useState<boolean>(false);

  const router = useRouter();
  const { socialType, socialId, email } = router.query as QueryParams;
  const isProviderUser = useMemo(() => !!socialType, [socialType]);

  useEffect(() => {
    if (isProviderUser) {
      // 소셜 로그인 사용자 정보 초기화
      setValue("isProvider", true);
      trigger("isProvider");
      setValue("email", email ? email : "");
      email && setIsInitialEmail(true);
    } else {
      setValue("isProvider", false);
      trigger("isProvider");
    }
  }, [isProviderUser, email]);

  const onShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const onShowMatchPassword = () => {
    setIsShowMatchPassword(!isShowMatchPassword);
  };

  const schema = yup.object({
    isProvider: yup.boolean(),
    name: yup
      .string()
      .trim(t("no_spaces_allowed"))
      .matches(
        /^[ㄱ-ㅎ가-힣a-zA-Z\s]+$/,
        t("name_available_in_korean_or_english_only"),
      )
      .required(t("enter_your_name")),
    email: yup
      .string()
      .trim(t("no_spaces_allowed"))
      .email(t("enter_valid_email_address"))
      .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
      .required(t("enter_email")),
    password: yup.string().when("isProvider", {
      is: false,
      then: (schema) =>
        schema
          .min(8, t("password_must_be_8_to_20_characters"))
          .max(20, t("password_must_be_8_to_20_characters"))
          .matches(/[a-zA-Z0-9d~!@#$%^&*()_+=]$/, "invalid_special_characters")
          .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
          .matches(
            /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
            t("pwd_must_use_combination"),
          )
          .trim(t("no_spaces_allowed"))
          .required(t("enter_password")),
    }),
    matchNewPassword: yup.string().when("isProvider", {
      is: false,
      then: (schema) =>
        schema
          .min(8, t("password_must_be_8_to_20_characters"))
          .max(20, t("password_must_be_8_to_20_characters"))
          .matches(
            /[a-zA-Z0-9d~!@#$%^&*()_+=]$/,
            t("invalid_special_characters"),
          )
          .matches(/^(?!.* )/, t("cannot_contain_empty_spaces"))
          .matches(
            /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]/,
            t("pwd_must_use_combination"),
          )
          .oneOf([yup.ref("password")], t("passwords_do_not_match"))
          .required(t("enter_password")),
    }),
    phoneNumber: yup
      .string()
      .trim()
      .matches(phoneRegex, t("invalid_phone_number_format"))
      .required(t("please_enter_your_phone_number")),
    verificationCode: yup
      .string()
      .trim()
      .required(t("please_enter_the_verification_code")),
    isAgeAgree: yup.boolean().required().isTrue(),
    isServiceAgree: yup.boolean().required().isTrue(),
    isPrivateAgree: yup.boolean().required().isTrue(),
    marketingOptIn: yup.boolean(),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<SignUpForm>({
    resolver: yupResolver<SignUpForm>(schema),
    mode: "onChange",
    defaultValues: {
      phoneNumber: "",
    },
  });

  const phoneNumber = watch("phoneNumber");
  const onChangePhoneNumber = async (e: ChangeEvent<HTMLInputElement>) => {
    setValue("phoneNumber", getFormattedPhoneNumber(e.target.value));
    await trigger("phoneNumber");
  };

  const onKeyDownPhoneNumber = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSendVerifyNumber();
    }
  };

  const sendVerifyNumberMutation = useAuthControllerSendVerification({
    mutation: {
      onSuccess: (data) => {
        //에러는 아니지만 인증번호 요청 후 요청 성공하면 인증번호 가이드 문구를 위해 에러를 발생시킨다.
        setError("verificationCode", {
          type: "SUCCESS",
          message: t("verification_code_sent_check_kakao_talk_sms"),
        });
        setIsVerifyNumberDisabled(false);
        setVerifyActionCount(verifyActionCount + 1);
        clearErrors("phoneNumber");
      },
      onError: (e) => {
        console.log(e);
      },
    },
  });

  const onSendVerifyNumber = async () => {
    sendVerifyNumberMutation.mutate({
      data: {
        identifier: phoneNumber.replaceAll("-", ""),
        method: "SMS",
        type: SendVerificationRequestDtoType.SIGNUP,
      },
    });
  };

  const onVerifyNumberEndTime = () => {
    if (!isSuccessVerify) {
      setIsVerifyNumberDisabled(true);
    }
  };

  const verifyNumberCheckMutation = useAuthControllerVerifyCode({
    mutation: {
      onSuccess: () => {
        setIsSuccessVerify(true);
        clearErrors("verificationCode");
      },
      onError: (e) => {
        // const { code, message } = e.response?.data;
        // setError("verificationCode", { type: code, message: message });
      },
    },
  });

  const verificationCode = watch("verificationCode");
  const onVerifyNumberCheck = () => {
    verifyNumberCheckMutation.mutate({
      data: {
        type: SendVerificationRequestDtoType.SIGNUP,
        method: "SMS",
        identifier: phoneNumber.replaceAll("-", ""),
        verificationCode: verificationCode!,
      },
    });
  };

  const onKeyDownVerifyNumber = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onVerifyNumberCheck();
    }
  };

  const isAgeAgree = watch("isAgeAgree");
  const isServiceAgree = watch("isServiceAgree");
  const isPrivateAgree = watch("isPrivateAgree");
  const marketingOptIn = watch("marketingOptIn");
  const onChangeAgree = async (
    fieldName:
      | "isAgeAgree"
      | "isServiceAgree"
      | "isPrivateAgree"
      | "marketingOptIn",
    isAgree: boolean,
  ) => {
    setValue(fieldName, isAgree);
    await trigger(fieldName);
  };

  const onChangeAllAgree = async (checked: boolean) => {
    await onChangeAgree("isAgeAgree", checked);
    await onChangeAgree("isServiceAgree", checked);
    await onChangeAgree("isPrivateAgree", checked);
    await onChangeAgree("marketingOptIn", checked);
  };

  const signUpMutation = useAuthControllerSignUp({
    mutation: {
      onSuccess: async ({ accessToken }) => {
        const result = await signIn("credentials", {
          accessToken,
          redirect: false,
        });

        if (result?.status === 200) {
          router.push(MENU.SIGNUP_SUCCESS.link.url);
        }
      },
      onError: (e) => {
        const data = e.response?.data;
        if (data) {
          const { errorCode } = data;
          let errorMsg;
          switch (errorCode) {
            case 100006:
              errorMsg = t("email_already_registered");
              break;
            default:
              errorMsg =
                `[CODE: ${errorCode}]` +
                t("failed_to_sign_up_please_contact_customer_center");
          }
          openToast(TOAST_TYPE.ERROR, errorMsg);
        }
      },
    },
  });

  const onSubmit: SubmitHandler<SignUpForm> = async (data) => {
    const authType = socialType ? "SOCIAL" : "EMAIL";
    const snsType = socialType
      ? (socialType as SignUpRequestDtoSocialType)
      : null;

    signUpMutation.mutate({
      data: {
        // ...data,
        name: data.name,
        password: data.password,
        email: data.email,
        marketingOptIn: data.marketingOptIn,
        phoneNumber: data.phoneNumber.replaceAll("-", ""),
        authType: authType,
        ...(socialId ? { socialId } : {}),
        ...(snsType ? { socialType: snsType } : {}),
        verificationCode: data.verificationCode,
      },
    });
  };

  const onError: SubmitErrorHandler<SignUpForm> = async (error) => {
    console.error(error);
  };

  return (
    <SignUpTemplateContainer>
      <SignUpTemplateInnerContainer>
        <SignUpCardContainer>
          <SignUpTitleContainer>{t("signup")}</SignUpTitleContainer>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <SignUpFormContainer>
              <SignUpFormInputContainer>
                <SignUpFormTextField
                  type="text"
                  label={t("name_real_name")}
                  placeholder={t("enter_your_name")}
                  {...register("name")}
                  isError={!!errors.name}
                  message={errors?.name?.message}
                  maxLength={40}
                />

                <SignUpFormTextField
                  type="text"
                  label={t("email")}
                  placeholder={t("enter_your_email")}
                  {...register("email")}
                  // disabled={!!user}
                  isError={!!errors.email}
                  message={errors?.email?.message}
                  maxLength={50}
                  disabled={initialEmail || false}
                />

                {!isProviderUser && (
                  <>
                    <SignUpFormTextField
                      type={isShowPassword ? "text" : "password"}
                      label={t("password")}
                      placeholder={t("pwd_combination_min_8_chars")}
                      {...register("password")}
                      maxLength={50}
                      isError={!!errors.password}
                      message={errors?.password?.message}
                      endAdornment={
                        <a onClick={onShowPassword}>
                          <Icon
                            icon={
                              isShowPassword
                                ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                                : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                            }
                          />
                        </a>
                      }
                    />

                    <SignUpFormTextField
                      type={isShowMatchPassword ? "text" : "password"}
                      label={t("confirm_password")}
                      placeholder={t("reenter_same_password")}
                      {...register("matchNewPassword")}
                      maxLength={50}
                      isError={!!errors.matchNewPassword}
                      message={errors?.matchNewPassword?.message}
                      endAdornment={
                        <a onClick={onShowMatchPassword}>
                          <Icon
                            icon={
                              isShowMatchPassword
                                ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                                : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                            }
                          />
                        </a>
                      }
                    />
                  </>
                )}

                <ButtonTextFieldContainer>
                  <SignUpFormTextField
                    type="text"
                    label={t("phone_number")}
                    placeholder={t("phone_number")}
                    isError={!!errors.phoneNumber}
                    message={errors?.phoneNumber?.message}
                    onChange={onChangePhoneNumber}
                    onKeyDown={onKeyDownPhoneNumber}
                    value={phoneNumber}
                    maxLength={13}
                    disabled={!isVerifyNumberDisabled}
                    endActionButton={
                      <FormButton
                        type="button"
                        styleType={BUTTON_TYPE.FILL}
                        // errors.phoneNumber.type !==
                        // PostKacAuthVerifyNumberSend400Code.E02) ||
                        disabled={
                          !!errors.phoneNumber ||
                          !phoneNumber ||
                          !isVerifyNumberDisabled
                        }
                        onClick={onSendVerifyNumber}
                      >
                        {verifyActionCount > 0
                          ? t("request_again")
                          : t("request_verification_code")}
                      </FormButton>
                    }
                  />
                </ButtonTextFieldContainer>

                <ButtonTextFieldContainer>
                  <SignUpFormTextField
                    type="text"
                    label={t("verification_code")}
                    placeholder={t("verification_code")}
                    isError={!!errors.verificationCode}
                    message={errors?.verificationCode?.message}
                    {...register("verificationCode")}
                    onKeyDown={onKeyDownVerifyNumber}
                    maxLength={6}
                    disabled={
                      isVerifyNumberDisabled ||
                      isSuccessVerify ||
                      sendVerifyNumberMutation.isPending
                    }
                    endAdornment={
                      !isSuccessVerify &&
                      !isVerifyNumberDisabled && (
                        <VerifyTimerSpan onEnd={onVerifyNumberEndTime} />
                      )
                    }
                    endActionButton={
                      <FormButton
                        type="button"
                        styleType={BUTTON_TYPE.FILL}
                        disabled={
                          verificationCode?.length < 4 ||
                          isVerifyNumberDisabled ||
                          (!!errors.verificationCode &&
                            errors.verificationCode.type !== "SUCCESS") ||
                          isSuccessVerify ||
                          verifyNumberCheckMutation.isPending ||
                          !verificationCode
                        }
                        onClick={onVerifyNumberCheck}
                      >
                        {isSuccessVerify
                          ? t("verification_complete")
                          : t("verify_code")}
                      </FormButton>
                    }
                  />
                </ButtonTextFieldContainer>
              </SignUpFormInputContainer>

              <AgreeContainer>
                <FormCheckBoxContainer className="all-agree">
                  <CheckBox
                    checked={
                      isAgeAgree &&
                      isServiceAgree &&
                      isPrivateAgree &&
                      marketingOptIn
                    }
                    onChange={(e) => onChangeAllAgree(e.target.checked)}
                  />
                  <span
                    onClick={() => {
                      onChangeAllAgree(
                        !(
                          isAgeAgree &&
                          isServiceAgree &&
                          isPrivateAgree &&
                          marketingOptIn
                        ),
                      );
                    }}
                  >
                    {t("agree_to_all")}
                  </span>
                </FormCheckBoxContainer>

                <Divider />

                <ChoiceAgreeContainer>
                  <FormCheckBoxContainer className="choice-agree">
                    <CheckBox
                      isError={!!errors.isAgeAgree}
                      checked={isAgeAgree}
                      onChange={(e) => {
                        onChangeAgree("isAgeAgree", !isAgeAgree);
                      }}
                    />
                    <span
                      onClick={() => onChangeAgree("isAgeAgree", !isAgeAgree)}
                    >
                      {t("i_am_14_years_or_older_required")}
                    </span>
                  </FormCheckBoxContainer>
                  <FormCheckBoxContainer className="choice-agree">
                    <CheckBox
                      isError={!!errors.isServiceAgree}
                      checked={isServiceAgree}
                      onChange={(e) => {
                        onChangeAgree("isServiceAgree", !isServiceAgree);
                      }}
                    />
                    <span
                      onClick={() =>
                        onChangeAgree("isServiceAgree", !isServiceAgree)
                      }
                    >
                      {t("agree_to_terms_of_service_required")}
                    </span>
                    <Link
                      href={""}
                      target={"_blank"}
                      passHref
                    >
                      {t("view")}
                    </Link>
                  </FormCheckBoxContainer>
                  <FormCheckBoxContainer className="choice-agree">
                    <CheckBox
                      isError={!!errors.isPrivateAgree}
                      checked={isPrivateAgree}
                      onChange={(e) => {
                        onChangeAgree("isPrivateAgree", !isPrivateAgree);
                      }}
                    />
                    <span
                      onClick={() =>
                        onChangeAgree("isPrivateAgree", !isPrivateAgree)
                      }
                    >
                      {t("agree_to_privacy_policy_required")}
                    </span>
                    <Link
                      href={""}
                      target={"_blank"}
                      passHref
                    >
                      {t("view")}
                    </Link>
                  </FormCheckBoxContainer>
                  <FormCheckBoxContainer className="choice-agree">
                    <CheckBox
                      isError={!!errors.marketingOptIn}
                      checked={marketingOptIn}
                      onChange={(e) => {
                        onChangeAgree("marketingOptIn", !marketingOptIn);
                      }}
                    />
                    <span
                      onClick={() =>
                        onChangeAgree("marketingOptIn", !marketingOptIn)
                      }
                    >
                      {t("agree_to_receive_marketing_information_optional")}
                    </span>
                    <Link
                      href={""}
                      target={"_blank"}
                      passHref
                    >
                      {t("view")}
                    </Link>
                  </FormCheckBoxContainer>
                </ChoiceAgreeContainer>
              </AgreeContainer>

              <SignUpButtonContainer>
                <SignUpButton
                  styleType={BUTTON_TYPE.FILL}
                  type={"submit"}
                  disabled={!isValid || signUpMutation.isPending}
                >
                  {t("signup")}
                </SignUpButton>
              </SignUpButtonContainer>
            </SignUpFormContainer>
          </form>
          <RegisteredUserLoginContainer>
            {t("already_have_an_account_question")}{" "}
            <Link
              href={MENU.LOGIN.link.url}
              passHref
            >
              {t("goto_signin")}
            </Link>
          </RegisteredUserLoginContainer>
        </SignUpCardContainer>
      </SignUpTemplateInnerContainer>
    </SignUpTemplateContainer>
  );
};

const SignUpTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignUpTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 96px 24px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  ${({ theme }) => theme.media.mobile} {
    padding: 96px 16px;
  }
`;

const SignUpCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 365px;

  gap: 40px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 343px;
  }
`;

const SignUpTitleContainer = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};

  text-align: center;
`;

const SignUpFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const SignUpFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SignUpFormTextField = styled(TextField)`
  gap: 8px;
  flex: 1 0 0;
  letter-spacing: -0.4px;
`;

const ButtonTextFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FormButton = styled(Button)`
  display: flex;
  padding: 14px 16px;
  align-self: flex-end;
  justify-content: center;
  align-items: center;

  min-width: 117px;

  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    border-radius: 4px;
  }
`;

const VerifyTimerSpan = styled(TimerSpan)`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;

  color: ${({ theme }) => theme.colors.state.error.default};
`;

const AgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ChoiceAgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  &.all-agree {
    span {
      cursor: pointer;
      font-size: 14px;
      font-style: normal;

      line-height: 100%; /* 14px */
      letter-spacing: 0.5px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.text.high.black};
    }
  }

  &.choice-agree {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 0;
    }

    span {
      cursor: pointer;
      font-size: 14px;
      font-style: normal;

      line-height: 100%; /* 14px */
      letter-spacing: 0.5px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.text.medium.black};
    }
  }

  a {
    margin-left: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const FinalAgreeDescription = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.text.medium.black};
  white-space: pre-line;

  b {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.gray.gray90};
`;

const SignUpButtonContainer = styled.div``;

const SignUpButton = styled(Button)`
  width: 100%;

  &.btn-fill {
    border-radius: 4px;
  }
`;

const RegisteredUserLoginContainer = styled.div`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.text.medium.black};
  a {
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;
